import { toggle } from './toggle';

export const toggleTogglers = (togglers, e) => {
  const isopenButton = e.target.getAttribute('aria-hidden');

  if (isopenButton === 'false') {
    // If the button is acting as an open button:
    for (let b = 0; b < togglers.length; b += 1) {
      toggle(togglers[b], 'true');
    }
    e.target.setAttribute('aria-hidden', true);
    e.target.innerHTML = 'Close All';
  } else {
    // If the button is acting as an close button:
    for (let c = 0; c < togglers.length; c += 1) {
      toggle(togglers[c], 'false');
    }
    e.target.setAttribute('aria-hidden', false);
    e.target.innerHTML = 'Open All';
  }
};
