/*
 * ACCESSIBLE TOGGLE ALL
 * =====================
 * The toggle all is an extension of the Accessible Toggler and allows a single
 * button to control a group of togglers to open/close all at once.
 *
 * Toggle All buttons use the class .js-toggle-all. Since the toggle all button
 * is only needed when there is JS it should have aria-hidden="true" by default.

 * All togglers in a group must be wrapped in the parent element that has a unique
 * ID. The ID attribute on the parent must match the aria-controls attribute on
 * the Toggle All button.
 *
 * HTML Example:
 * <button class="js-toggle-all" aria-controls="uniqueID" aria-hidden="true">Open All</button>
 * <div id="uniqueID">...</div>
 */
import { toggleTogglers } from './toggle-togglers';

export const toggleAll = () => {
  const toggleAllButtons = document.querySelectorAll('.js-toggle-all');
  if (toggleAllButtons) {
    for (let a = 0; a < toggleAllButtons.length; a += 1) {
      const listToggler = toggleAllButtons[a];
      const listID = listToggler.getAttribute('aria-controls');
      const list = document.querySelector(`#${listID}`);
      const togglers = list.querySelectorAll('.js-toggler');

      // By default we set aria-hidden on the listToggler to true and the update
      // the value if there is JS. This will ensure that if there isn't
      // If there is no JS on the page then this button should not be visible
      // and would be confusing to screen reader users.
      listToggler.setAttribute('aria-hidden', false);

      listToggler.addEventListener('click', (e) => {
        toggleTogglers(togglers, e);
      });
    }
  }
};
