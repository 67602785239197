// VENDOR
import './vendor/glider-compat.min';
import './vendor/glider';

// CUSTOM
import './init';
import './ie11';
import { toggler } from './toggler';
import './menu-toggler';
import { subnavInit } from './subnav-toggler';
import './search-toggler';
import './smooth-scroll';
import './safe-focus';
import './sticky-shrink';
import { videoWrapper } from './video-wrapper';
import { toggleAll } from './toggle-all';

// Start Video Wrapper
videoWrapper();

// Start Togglers
toggler();
toggleAll();
subnavInit();
