/*
 * ACCESSIBLE TOGGLER
 * ==================
 * Add the js-toggler class to any element to create a toggler. The ID attribute
 * on the toggler must match the aria-labelledby attribute of the item being toggled.
 *
 * HTML Example:
 * <button id="uniqueID" class="js-toggler" aria-expanded="true">Toggle</button>
 * <div aria-labelledby="uniqueID" aria-hidden="false">...</div>
 */

import { toggle } from './toggle';

export const toggler = () => {
  const togglers = document.querySelectorAll('.js-toggler');

  if (togglers) {
    for (let i = 0; i < togglers.length; i += 1) {
      const togglerItems = togglers[i];
      const togglerID = togglerItems.getAttribute('id');
      const togglerContent = document.querySelector(`[aria-labelledby="${togglerID}"]`);
      // Hide the togglable content on page load
      togglerContent.setAttribute('aria-hidden', true);
      togglerItems.setAttribute('aria-expanded', false);

      togglerItems.addEventListener('click', () => {
        const hiddenContent = togglerContent.getAttribute('aria-hidden');

        // Toggle the aria-hidden and aria-expanded values on click
        toggle(togglerItems, hiddenContent);
      });
    }
  }
};
