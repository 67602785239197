import { bodyFixed } from './body-fixed';

const handleMenuButtonClick = () => {
  const bodyEl = document.body;
  const openMenuBtn = document.querySelector('.js-menu-open');
  const closeMenuBtn = document.querySelector('.js-menu-close');
  const siteNavClass = '.js-menu';
  const siteNav = document.querySelector(siteNavClass);
  const headerSwitchBreakpoint = 960;

  const openState = 'cmp-nav--open';
  const openingState = 'cmp-nav--opening';

  const closedState = 'cmp-nav--closed';
  const closingState = 'cmp-nav--closing';

  const addClass = (el, className) => {
    el.classList.add(className);
  };

  const removeClass = (el, className) => {
    el.classList.remove(className);
  };

  // Opening to Open State
  const openNav = () => {
    bodyFixed('open', bodyEl);
    addClass(siteNav, openingState);

    siteNav.focus();

    siteNav.addEventListener('animationend', () => {
      addClass(siteNav, openState);
      removeClass(siteNav, openingState);
      removeClass(siteNav, closedState);
    });

    openMenuBtn.setAttribute('aria-expanded', 'true');
    siteNav.setAttribute('aria-hidden', 'false');
  };

  // Closing to Closed State
  const closeNav = () => {
    bodyFixed('closed', bodyEl);
    addClass(siteNav, closingState);
    removeClass(siteNav, openState);

    siteNav.addEventListener('animationend', () => {
      addClass(siteNav, closedState);
      removeClass(siteNav, openState);
      removeClass(siteNav, closingState);
    });

    openMenuBtn.setAttribute('aria-expanded', 'false');
    siteNav.setAttribute('aria-hidden', 'true');
  };

  // Open Nav on Click
  const openNavTrigger = () => {
    openMenuBtn.addEventListener('click', (e) => {
      e.preventDefault();

      if (siteNav.classList.contains(closedState)) {
        openNav();
      }
    });
  };

  // Close Nav on Click
  const closeNavTrigger = () => {
    closeMenuBtn.addEventListener('click', (e) => {
      e.preventDefault();

      if (siteNav.classList.contains(openState)) {
        closeNav();
      }
    });
  };

  const handleClickOutside = () => {
    document.addEventListener('click', (event) => {
      if (siteNav.classList.contains(openState) && !event.target.closest(siteNavClass)) {
        closeNav();
      }
    }, false);
  };

  // event listener for keyup
  const checkFocus = () => {
    const focusedElement = document.activeElement;
    if (!focusedElement.closest(siteNavClass) || !focusedElement.parentNode.closest(siteNavClass)) {
      if (siteNav.classList.contains(openState)) {
        closeNav();
      }
    }
  };

  const inithandleMenuButtonClick = () => {
    if (!siteNav) return;
    if (siteNav && window.innerWidth < headerSwitchBreakpoint) {
      siteNav.classList.add(closedState);

      openNavTrigger();
      closeNavTrigger();
      handleClickOutside();

      document.addEventListener('keyup', checkFocus);

      openMenuBtn.setAttribute('aria-expanded', 'false');
      siteNav.setAttribute('aria-hidden', 'true');
    } else {
      siteNav.classList.remove(closedState);

      openMenuBtn.setAttribute('aria-expanded', 'true');
      siteNav.setAttribute('aria-hidden', 'false');
    }
  };

  inithandleMenuButtonClick();
};

handleMenuButtonClick();

// When the window is resized we need to check for changes to aria attributes.
// The following code includes a debounce method so that the JS is only fired
// once after the page is done resizing.
let timeout;
const delay = 100;

window.addEventListener('resize', () => {
  clearTimeout(timeout);
  timeout = setTimeout(handleMenuButtonClick, delay);
});
