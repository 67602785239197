export const videoWrapper = () => {
  const videos = document.querySelectorAll('iframe[src*="youtube"]');
  const buildWrapper = () => {
    if (!videos.length) { return; }

    [...videos].forEach((video) => {
      const videoParentClass = video.parentElement.classList;
      if (!videoParentClass.contains('cmp-video')) {
        const wrapper = document.createElement('div');

        wrapper.classList.add('cmp-video');
        video.classList.add('cmp-video__embed');
        video.parentNode.insertBefore(wrapper, video);
        wrapper.appendChild(video);
      }
    });
  };

  buildWrapper();
};
