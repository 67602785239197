import { setIconState } from './set-icon-state';

const handleSearchButtonClick = () => {
  const searchToggler = document.querySelector('.js-search-toggle');
  const searchContainer = document.querySelector('.js-search');
  const searchField = document.querySelector('.js-search-field');
  const headerSwitchBreakpoint = 800; // Medium breakpoint

  let hiddenIcon;
  let expandedIcon;
  let hiddenContent = true;
  let expandedContent = false;

  if (searchToggler) {
    hiddenIcon = searchToggler.getAttribute('data-icon-hidden');
    expandedIcon = searchToggler.getAttribute('data-icon-expanded');
    searchToggler.classList.add(hiddenIcon);
  }

  const setAriaState = () => {
    searchContainer.setAttribute('aria-hidden', hiddenContent);
    searchToggler.setAttribute('aria-expanded', expandedContent);
  };

  const searchOpen = () => {
    setAriaState();
    setIconState('open', searchToggler, hiddenIcon, expandedIcon);
    searchField.focus();
  };

  const searchClose = () => {
    setAriaState();
    setIconState('hidden', searchToggler, hiddenIcon, expandedIcon);
  };

  const inithandleSearchButtonClick = () => {
    if (!searchContainer || !searchToggler) return;

    // Might need to move this conditional into the click handler
    if (searchContainer && searchToggler && window.innerWidth < headerSwitchBreakpoint) {
      if (!searchToggler.hasAttribute('aria-expanded')) {
        setAriaState();
      }

      searchToggler.addEventListener('click', () => {
        if (hiddenContent) {
          hiddenContent = false;
          expandedContent = true;
          searchOpen();
        } else {
          hiddenContent = true;
          expandedContent = false;
          searchClose();
        }
      });
    } else {
      searchContainer.removeAttribute('aria-hidden');
      searchToggler.removeAttribute('aria-expanded');
      searchToggler.classList.remove(expandedIcon);
    }
  };

  inithandleSearchButtonClick();
};

handleSearchButtonClick();

// When the window is resized we need to check for changes to aria attributes.
// The following code includes a debounce method so that the JS is only fired
// once after the page is done resizing.
let timeout;
const delay = 100;

window.addEventListener('resize', () => {
  clearTimeout(timeout);
  timeout = setTimeout(handleSearchButtonClick, delay);
});
